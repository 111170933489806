<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    themeColor() {
      return this.$store.getters.themeColor || "#01d0a4";
    },
  },
  watch: {
    themeColor(val) {
      document.getElementById("app").style.setProperty("--themeColor", val);
    },
  },
  mounted() {
    document
      .getElementById("app")
      .style.setProperty("--themeColor", this.themeColor);
  },
};
</script>

<style lang="scss">
#app {
  height: 100%;
  font-family: PingFangSC-Regular, Microsoft YaHei, sans-serif;
  background-color: #f4f4f6;
  --themeColor: #01d0a4;
}
</style>
