<template>
  <div class="login-container">
    <!--    <img class="login-bg" :src="require('@img/login-bg.png')">-->
    <section class="el-container is-vertical">
      <header class="el-header" style="height: 60px">
        <div class="el-logo"></div>
        <div class="el-com">万物互联 成就智慧生活</div>
      </header>
      <main class="el-main">
        <div class="el-mainleft">
          <el-carousel :interval="5000" arrow="always" height="440px">
            <el-carousel-item v-for="item in 3" :key="item"> </el-carousel-item>
          </el-carousel>
        </div>
        <div class="el-mainright">
          <!--          <div class="el-mainrightheader">见登物联网平台</div>-->
          <el-form
            :model="form"
            :rules="rules"
            ref="form"
            class="login-form"
            label-position="left"
          >
            <div class="project-title">
              <!--              {{ require("@/settings.js").title }}-->
              新风管理系统
            </div>
            <!--            <div class="line"></div>-->
            <div class="login-tab">
              <span
                class="account-login"
                :class="active === 1 ? 'login-active' : ''"
                @click="active = 1"
                >账号密码登录</span
              >
              <span
                class="valid-login"
                :class="active === 2 ? 'login-active' : ''"
                @click="active = 2"
                >验证码登录</span
              >
            </div>
            <div class="account-login-content" v-if="active === 1">
              <el-form-item class="login-form-username" prop="username">
                <div class="uicon"></div>
                <input
                  class="username-input"
                  placeholder="请输入邮箱或者手机号"
                  v-model="form.username"
                  name="username"
                  @keyup.enter="login"
                  auto-complete="on"
                />
              </el-form-item>
              <el-form-item class="login-form-pwd" prop="password">
                <div class="picon"></div>
                <input
                  class="username-input"
                  type="password"
                  auto-complete="new-password"
                  placeholder="请输入密码"
                  v-model="form.password"
                  @keyup.enter="login"
                />
              </el-form-item>
            </div>
            <div class="valid-login-content" v-if="active === 2">
              <el-form-item class="login-form-username" prop="phone">
                <input
                  class="username-input"
                  placeholder="请输入手机号"
                  v-model="form_phone.phone"
                  name="username"
                />
              </el-form-item>
              <el-form-item class="login-form-valid" prop="valid_num">
                <input
                  class="username-input"
                  placeholder="请输入验证码"
                  v-model="form_phone.valid_num"
                  name="username"
                />
                <div class="valid-num">
                  <span v-if="codeShow" @click="getPhoneCode">获取验证码</span>
                  <span v-if="!codeShow">{{ count }}</span>
                </div>
              </el-form-item>
            </div>
            <!--            <a-->
            <!--              href="javascript:void(0);"-->
            <!--              class="forget-pwd"-->
            <!--              @click="$router.push({ path: '/login/resetPasswordRequest' })"-->
            <!--              >忘记密码?</a-->
            <!--            >-->
            <el-form-item class="login-form-btn">
              <el-button type="primary" :loading="loading" @click="login"
                >登 录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </main>
      <footer class="el-footer" style="height: 60px">
        <pre>江苏见登智能科技有限公司    苏ICP备2021010620号<br />
          地址:江苏省苏州市太湖东路9号武珞科技园    联系电话：0512-65765088</pre>
      </footer>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      count: "",
      codeShow: true,
      timer: null,
      active: 1,
      form: {
        username: "",
        password: "",
      },
      form_phone: {
        phone: "",
        valid_num: "",
      },
      rules: {
        username: {
          required: true,
          message: "用户名不能为空",
          trigger: "change",
        },
        password: {
          required: true,
          message: "密码不能为空",
          trigger: "change",
        },
      },
      loading: false,
    };
  },
  methods: {
    login() {
      if (this.active === 2) {
        let test = /^1[3-9]\d{9}$/.test(this.form_phone.phone);
        if (!test) {
          this.$message({
            message: "手机号格式不正确",
            type: "error",
          });
          return;
        }
        if (!this.form_phone.valid_num) {
          this.$message({
            message: "请输入手机验证码",
            type: "error",
          });
          return;
        }
        this.loading = true;
        try {
          this.$store.dispatch("phoneLogin", {
            mobilePhone: this.form_phone.phone,
            code: this.form_phone.valid_num,
          });
        } catch (err) {
          console.log(err);
        }
        this.loading = false;
      } else {
        this.$refs.form.validate(async (valid) => {
          if (!valid) return false;
          this.loading = true;
          try {
            await this.$store.dispatch("login", this.form);
          } catch (err) {
            console.log(err);
          }
          this.loading = false;
        });
      }
    },
    getPhoneCode() {
      if (this.timer) return;
      let test = /^1[3-9]\d{9}$/.test(this.form_phone.phone);
      if (!test) {
        this.$message({
          message: "手机号格式不正确",
          type: "error",
        });
        return;
      }
      const TIME_COUNT = 60; //倒计时60秒
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.codeShow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.codeShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      this.$http.getAuthCode(this.form_phone.phone).then((res) => {
        if (res.data?.success) {
          this.$message({
            message: "验证码已发送",
            type: "success",
          });
        } else {
          this.$message({
            message: "验证码发送失败",
            type: "error",
          });
        }
      });
    },
    // closeDialog() {
    //   this.$store.dispatch("logout");
    //   const dialog = document.getElementsByClassName("icloud-dialog__wrapper");
    //   for (let i = 0; i < dialog.length; i++) {
    //     dialog[i].style.display = "none";
    //   }
    // },
  },
  created() {
    // this.$store.dispatch("websocketAllUnsubscribe");
    // const websocket = this.$store.state.websocket.websocket;
    // websocket && websocket.close();
    // this.closeDialog();
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  width: 100%;
  height: 100%;
  min-height: 900px;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#00d4a1), to(#01c1b4));
  position: relative;
  .login-bg {
    width: calc(100% - 765px);
    height: 100%;
  }
  .el-container {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1100px;
    height: 600px;
    margin-bottom: 40px;
    position: absolute;
    .el-header {
      color: #ffffff;
      font-size: 29px;
      line-height: 80px;
      // text-align: center;
    }

    .el-footer {
      top: 30px;
      color: #ffffff;
      font-size: 14px;
      text-align: center;
      line-height: 20px;
      position: relative;
    }

    .el-logo {
      width: 150px;
      top: 25px;
      height: 37px;
      position: relative;
      background: url("../../../public/logo.png");
      -webkit-background-size: 150px 37px;
    }
    .el-com {
      width: 350px;
      height: 37px;
      position: absolute;
      top: 0;
      letter-spacing: 4px;
      left: 200px;
    }
    .el-main {
      display: flex;
      width: 1100px;
      height: 450px;
      //background-color: #e9eef3;
      color: #333;
      text-align: center;
    }
    .el-mainleft {
      //display: flex;
      width: 50%;
      height: 100%;
      //background-color: #b0ece6;
      //color: #333;
      text-align: center;
    }
    .el-carousel__item h3 {
      position: relative;
      height: 100%;
      //color: #475669;
      font-size: 18px;
      //height: 100%;
      opacity: 0.75;
      line-height: 300px;
      margin: 0;
    }

    .el-carousel__item:nth-child(n) {
      background: url("../../assets/images/login/lunbo2.jpg");
      -webkit-background-size: 530px 440px;
    }

    .el-carousel__item:nth-child(2n) {
      background: url("../../assets/images/login/lunbo1.jpg");
      -webkit-background-size: 530px 440px;
    }

    .el-carousel__item:nth-child(3n) {
      background: url("../../assets/images/login/lunbo.jpg");
      -webkit-background-size: 530px 440px;
    }

    .el-mainright {
      //display: flex;
      width: 50%;
      height: 100%;
      background-color: #ffffff;
      color: #333;
      text-align: center;
      line-height: 100px;
    }
    .el-mainrightheader {
      position: relative;
      top: 0;
      width: 100%;
      height: 80px;
      background-color: #ffffff;
      color: #333;
      text-align: center;
      line-height: 100px;
      font-size: 30px;
    }
    .uicon {
      float: left;
      margin-top: 2px;
      //position: relative;
      background: url("../../assets/images/login/username.png");
      //-webkit-background-size: 20px 20px;
      width: 25px;

      height: 25px;
    }
    .picon {
      float: left;
      margin-top: 2px;
      //position: relative;
      background: url("../../assets/images/login/password.png");
      //-webkit-background-size: 20px 20px;
      width: 25px;
      height: 25px;
    }
    .forget-pwd {
      font-size: 16px;
      font-family: PingFangSC-Medium;
      color: #939393;
      width: 50%;
      line-height: 22px;
      float: right;
      text-align: right;
      margin-top: 8px;
      margin-bottom: 20px;
    }

    .login-form {
      width: 360px;
      height: 380px;
      position: relative;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
      .login-form-valid {
        position: relative;
      }
      .valid-num {
        position: absolute;
        width: 100px;
        border: 1px #cccccc solid;
        border-radius: 10px;
        right: 0;
        top: -10px;
        cursor: pointer;
      }
      .project-title {
        color: #000;
        position: relative;
        font-size: 30px;
        //font-family: PingFangSC-Medium;
        font-weight: normal;
        line-height: 60px;
        border-bottom-color: #000000;
        border-bottom-width: 1px;
        //border-width:1px;
        margin-top: 40px;
        //margin-bottom: 50px;
        //bottom: 50px;
      }
      .login-tab {
        width: 100%;
        height: 40px;
        display: flex;
        border: 1px #01c1b4 solid;
        border-radius: 12px;
        margin-top: 10px;
        .account-login,
        .valid-login {
          width: 50%;
          height: 100%;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          //border: 1px #01c1b4 solid;
        }
        .account-login {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        .valid-login {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        .login-active {
          background-color: #01d0a4;
          border: none;
        }
      }
      .line {
        height: 2px;
        background-color: #16c791;
        margin-bottom: 60px;
      }
      .el-form-item {
        margin-bottom: 30px;
        height: 40px;
        line-height: 25px;
        //.el-form-item__label {
        //  font-size: 16px;
        //  font-family: PingFangSC-Medium;
        //  color: #939393;
        //  // line-height: 22px;
        margin-top: 30px;
        padding-bottom: 5px;
        border-bottom: 1px solid #dcdfe6;
        //  margin-bottom: 17px;
        //  position: relative;
        //  width: 30px;
        //  //&::before {
        //  //  content: "";
        //  //}
        //}
        .username-input {
          width: 90%;
          height: 23px;
          margin-bottom: 20px;
          //background-color: #f5f6fa;
          color: #1c1c1c;
          //border-width: 0px;
          font-size: 16px;
          border: none;
          font-family: PingFangSC-Medium;
          outline: none;
        }
        input::-webkit-input-placeholder {
          color: #999;
        }

        input::-ms-input-placeholder {
          color: #999;
        }

        .el-input__inner {
          width: 90%;
          height: 59px;
          background-color: #f5f6fa;
          color: #1c1c1c;
          //border-width: 0px;
          font-size: 18px;
          border: none;
          font-family: PingFangSC-Medium;
        }
        input::-webkit-input-placeholder {
          color: #999;
        }
        //input::-moz-input-placeholder {
        //  color: #999;
        //}
        input::-ms-input-placeholder {
          color: #999;
        }

        &.login-form-btn {
          margin-top: 45px;
          border-bottom: 1px solid #ffffff;
          .el-button {
            border-color: #01c3b2;
            background-color: #01c3b2;
            border-radius: 28px;
            font-size: 15px;
            padding: 13px 150px;
            span {
              color: #fff;
              font-size: 10px;
              font-family: PingFangSC-Medium;
            }
          }
        }
      }
    }
  }
}
</style>
