import { request } from "@/service/request";

export default {
  getLocalWeather: () => request({ url: `newAir/outdoorWeather` }),

  //获取资产名称
  getAssetInfo: (assetId) => request({ url: `asset/${assetId}` }),

  //获取资产ID
  getAssetID: () => request({ url: `newAir/getCustomerAssetId` }),

  //单设备状态
  getSingleDeviceStatus: (deviceId) =>
    request({ url: `newAir/findOneDeviceStatus/${deviceId}` }),

  //获取logo地址
  getLogoUrl: (assetId) => request({ url: `newAir/findSchoolLogo/${assetId}` }),

  //统一更改device状态
  postDeviceState: (deviceId, data) =>
    request({
      method: "post",
      url: `plugins/rpc/twoway/${deviceId}`,
      params: { method: "setMore", params: data, timeout: 3000 },
    }),

  //开机添加数据
  PowerOnDevice: (params) =>
    request({
      method: "post",
      url: `newAir/addOnlineStatistics`,
      params: params,
    }),
};
