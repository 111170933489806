<template>
  <div class="tab-container">
    <span
      v-for="item in tabs"
      :key="item.id"
      :class="[active === item.id ? 'is-active' : '']"
      @click="
        active = item.id;
        changeChildTab(item.id);
      "
      >{{ item.title }}</span
    >
  </div>
</template>

<script>
export default {
  name: "TableTab",
  props: {
    tabs: Array,
    active: {
      default: 1,
    },
  },
  // data() {
  //   return {
  //     active: 1,
  //   };
  // },
  beforeDestroy() {
    this.$off("changeTab");
  },
  methods: {
    changeChildTab(id) {
      this.$emit("changeTab", { id });
    },
  },
};
</script>

<style scoped lang="scss">
.tab-container {
  width: 100%;
  height: 36px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  > span {
    width: 100px;
    height: 100%;
    background-color: #e5e5e5;
    color: #333;
    cursor: pointer;
    margin-right: 5px;
    border-top-right-radius: 8px;
    line-height: 36px;
    text-align: center;
    &.is-active {
      background-color: #01cfa5;
      color: #fff;
    }
  }
}
</style>
