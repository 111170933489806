/**
 * 我的消息
 */
import { request } from "@/service/request";

export default {
  findCustomerMessage: () =>
    request({ url: `backgroundManagement/myMessage/findCustomerMessage` }),
  updateCustomerMessageStatus: (publishId, status) =>
    request({
      url: `backgroundManagement/myMessage/updateCustomerMessageStatus/${publishId}?status=${status}`,
    }),
  findAllContactUsByCustomer: () =>
    request({
      url: `backgroundManagement/contactUs/findAllContactUsByCustomer`,
    }),
};
