/**
 * 具体设备
 */
import { request } from "@/service/request";

export default {
  //单设备滤网信息
  getDeviceFilter: (deviceId) =>
    request({ url: `newAir/filterTimeRemaining?entityId=${deviceId}` }),

  // 查询范围时间的传感器数据
  getRangeData: (deviceId, frequency, starttime, endtime, fields) =>
    request({
      url: `newAir/allTrend/${deviceId}?frequency=${frequency}&starttime=${starttime}&endtime=${endtime}&fields=${fields}`,
    }),
  //日周月历史趋势
  getRangeHistoryData: (deviceId, days, frequency, fields) =>
    request({
      url: `newAir/alldwmTrend/${deviceId}?days=${days}&frequency=${frequency}&fields=${fields}`,
    }),
};
