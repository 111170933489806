/**
 * 系统信息
 */
import { request } from "@/service/request";

export default {
  //初，中，高效率待维护滤网列表
  setNewAssetName: (assetId, name) =>
    request({ url: `newAir/updataSchoolName/${assetId}?SchoolAsset=${name}` }),
  //获取用户信息
  getUserProfile: () => request({ url: "auth/user" }),
  //修改绑定手机号
  changeUserPhone: (params) =>
    request({ url: "newAir/bindingMobilePhone", method: "post", params }),
};
