import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/login/Login";
import Layout from "../views/layout/Layout";

Vue.use(VueRouter);

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "",
    redirect: "/dashboards",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/dashboards",
    component: Layout,
    children: [
      {
        path: "",
        component: () => import("@/views/home/Home"),
        meta: {
          isBreadcrumb: false,
        },
      },
    ],
  },
  {
    path: "/detail",
    component: Layout,
    children: [
      {
        path: "maintain",
        component: () => import("@/views/detail/Maintain"),
        meta: {
          isBreadcrumb: true,
          breadcrumb: [
            { title: "返回首页", path: "/dashboards" },
            { title: "设备维护", path: "" },
          ],
        },
        // meta: {
        //   breadcrumb: [{ title: "首页", path: "/" }],
        // },
      },
      {
        path: "filter",
        component: () => import("@/views/detail/FilterManage"),
        meta: {
          isBreadcrumb: true,
          breadcrumb: [
            { title: "返回首页", path: "/dashboards" },
            { title: "滤网管理", path: "" },
          ],
        },
      },
    ],
  },
  {
    path: "/charts",
    component: Layout,
    children: [
      {
        path: ":asset",
        component: () => import("@/views/charts/Charts"),
        meta: {
          isBreadcrumb: true,
          breadcrumb: [
            // { title: "返回首页", path: "/dashboards" },
            // { title: "", path: "" },
          ],
        },
        // meta: {
        //   breadcrumb: [{ title: "首页", path: "/" }],
        // },
      },
    ],
  },
  {
    path: "/profile",
    component: Layout,
    children: [
      {
        path: "",
        component: () => import("@/views/profile/Profile"),
        meta: {
          isBreadcrumb: true,
          breadcrumb: [
            { title: "返回首页", path: "/dashboards" },
            { title: "系统信息", path: "" },
          ],
        },
        // meta: {
        //   breadcrumb: [{ title: "首页", path: "/" }],
        // },
      },
    ],
  },
  {
    path: "/myMessage",
    component: Layout,
    children: [
      {
        path: "",
        component: () => import("@/views/mymessage/index"),
        meta: {
          isBreadcrumb: true,
          breadcrumb: [
            { title: "返回首页", path: "/dashboards" },
            { title: "我的消息", path: "" },
          ],
        },
        // meta: {
        //   breadcrumb: [{ title: "首页", path: "/" }],
        // },
      },
    ],
  },
  {
    path: "/HelpInfo",
    component: Layout,
    children: [
      {
        path: "",
        component: () => import("@/views/help-info/index"),
        meta: {
          isBreadcrumb: true,
          breadcrumb: [
            { title: "返回首页", path: "/dashboards" },
            { title: "客户帮助", path: "" },
          ],
        },
        // meta: {
        //   breadcrumb: [{ title: "首页", path: "/" }],
        // },
      },
      {
        path: "detail",
        component: () => import("@/views/help-info/detail"),
        meta: {
          isBreadcrumb: true,
          breadcrumb: [
            { title: "返回首页", path: "/dashboards" },
            { title: "客户帮助", path: "/HelpInfo" },
            { title: "帮助详情", path: "" },
          ],
        },
        // meta: {
        //   breadcrumb: [{ title: "首页", path: "/" }],
        // },
      },
    ],
  },
  {
    path: "/contactUs",
    component: Layout,
    children: [
      {
        path: "",
        component: () => import("@/views/contact-us/index"),
        meta: {
          isBreadcrumb: true,
          breadcrumb: [
            { title: "返回首页", path: "/dashboards" },
            { title: "联系我们", path: "" },
          ],
        },
        // meta: {
        //   breadcrumb: [{ title: "首页", path: "/" }],
        // },
      },
    ],
  },
  {
    path: "/alarm",
    component: Layout,
    children: [
      {
        path: "",
        component: () => import("@/views/alarm/Alarm"),
        meta: {
          isBreadcrumb: true,
          breadcrumb: [
            { title: "返回首页", path: "/dashboards" },
            { title: "告警信息", path: "" },
          ],
        },
        // meta: {
        //   breadcrumb: [{ title: "首页", path: "/" }],
        // },
      },
    ],
  },
  //
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
