<template>
  <div class="layout-wrapper">
    <el-header>
      <div class="logo">
        <div class="left">
          <img
            :src="this.$store.state.logoUrl || '../../assets/images/logo.png'"
            alt=""
          />
          <span class="left-title">见登智能物联网平台 - 新风系统</span>
          <span class="line"></span>
          <span class="left-title">{{ $store.state.assetName }}</span>
        </div>
        <div class="right">
          <el-color-picker
            v-model="themeColor"
            size="small"
            @change="changeThemeColor"
            :predefine="predefineColors"
          ></el-color-picker>
          <local-weather class="weather"></local-weather>
          <user-menu></user-menu>
        </div>
      </div>
    </el-header>
    <el-container>
      <el-aside :width="isCollapse ? '60px' : '160px'">
        <side-bar :data="sideData"></side-bar>
      </el-aside>
      <el-main>
        <breadcrumb v-if="isBreadcrumb"></breadcrumb>
        <router-view class="middle"></router-view>
        <el-footer>
          <span>江苏见登智能科技有限公司 苏ICP备2021010620号</span>
          <span
            >地址：江苏省苏州市太湖东路9号武珞科技园
            联系电话：0512-65765088</span
          >
        </el-footer>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {
  LocalWeather,
  SideBar,
  UserMenu,
  Breadcrumb,
} from "@/components/layout";

export default {
  name: "Layout",
  components: {
    LocalWeather,
    SideBar,
    UserMenu,
    Breadcrumb,
  },
  data() {
    return {
      sideData: [
        {
          index: "1",
          title: "一年级",
          children: [
            {
              index: "1-1",
              title: "一年级一班",
            },
            {
              index: "1-2",
              title: "一年级二班",
            },
          ],
        },
        {
          index: "2",
          title: "二年级",
          children: [
            {
              index: "1-1",
              title: "二年级一班",
            },
            {
              index: "1-2",
              title: "二年级二班",
            },
            {
              index: "1-3",
              title: "二年级三班",
            },
          ],
        },
        {
          index: "3",
          title: "图书馆",
          children: [],
        },
      ],
      themeColor: "#01d0a4",
      predefineColors: [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "rgba(255, 69, 0, 0.68)",
        "rgb(255, 120, 0)",
        "hsv(51, 100, 98)",
        "hsva(120, 40, 94, 0.5)",
        "hsl(181, 100%, 37%)",
        "hsla(209, 100%, 56%, 0.73)",
        "#c7158577",
      ],
    };
  },
  computed: {
    isBreadcrumb() {
      const meta = this.$route.meta;
      if (meta) {
        return meta.isBreadcrumb === true;
      }
      return false;
    },
    isCollapse() {
      return this.$store.state.isCollapse;
    },
  },
  methods: {
    changeThemeColor(e) {
      if (e) {
        this.$store.commit("SET_THEME_COLOR", e);
      } else {
        this.$store.commit("SET_THEME_COLOR", "#01d0a4");
      }
    },
  },
  created() {
    this.$store.dispatch("userLoginProfile");
    this.$store.dispatch("getAssetNameAction");
    this.$store.dispatch("getLogoUrl");
  },
  mounted() {
    this.themeColor = this.$store.getters.themeColor;
  },
};
</script>

<style scoped lang="scss">
.layout-wrapper {
  height: 100%;
  .el-header {
    width: 100%;
    min-width: 1300px;
    height: 90px !important;
    background: var(--themeColor);
    padding: 0 24px;
    .logo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 100%;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 42px;
          height: 42px;
        }
        .left-title {
          font-size: 20px;
          color: #fff;
          margin-left: 15px;
          letter-spacing: 1px;
        }
        .line {
          width: 2px;
          height: 20px;
          //background: #5ee8d9;
          background: var(--themeColor);
          margin-left: 15px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 18px;
        .weather {
          margin-right: 35px;
        }
        .el-color-picker {
          margin-right: 20px;
        }
      }
    }
  }
  .el-container {
    height: calc(100% - 100px);
    .el-aside {
      //width: 160px !important;
      overflow-x: hidden;
      overflow-y: auto;
      transition: width 0.3s linear;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .el-main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .middle {
        flex: 1;
      }
      .el-footer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background-color: #fff;
        height: 60px !important;
        bottom: 10px;
        margin-top: 15px;
        width: 100%;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
        opacity: 0.5;
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
