export function weekTransform(num) {
  switch (num) {
    case 0:
      return "日";
    case 1:
      return "一";
    case 2:
      return "二";
    case 3:
      return "三";
    case 4:
      return "四";
    case 5:
      return "五";
    case 6:
      return "六";
  }
}

//获取过去7天的日期
export function getBeforeDate(number) {
  const num = number;
  const date = new Date();
  let mon = date.getMonth() + 1;
  let day = date.getDate();
  if (day <= num) {
    if (mon > 1) {
      mon = mon - 1;
    } else {
      mon = 12;
    }
  }
  date.setDate(date.getDate() - num);
  mon = date.getMonth() + 1;
  day = date.getDate();
  const s = (mon < 10 ? "0" + mon : mon) + "-" + (day < 10 ? "0" + day : day);
  return s;
}

export function dateFormat(fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}
