/**
 * 首页
 */
import { request } from "@/service/request";

export default {
  //初，中，高效率待维护滤网列表
  getAllFilter: (assetId, type) =>
    request({ url: `newAir/allfilter/${assetId}?type=${type}` }),
  //新风系统待维护列表
  getAllMaintain: (assetId) =>
    request({ url: `newAir/allmaintain/${assetId}` }),
  // 滤网复位
  filterReset: (params) =>
    request({ url: `newAir/filterReset`, method: "post", params: params }),
  //小时图表
  getHourChart: (deviceId, days, frequency, fields) =>
    request({
      url: `environment/hourChart/${days}?entityId=${deviceId}&frequency=${frequency}&fields=${fields}`,
    }),
  //提交申请
  postMaintainRequest: (deviceId, status) =>
    request({ url: `newAir/updataMaintain/${deviceId}?status=${status}` }),
};
