<template>
  <el-menu
    text-color="#333"
    :default-active="active"
    :collapse="isCollapse"
    :collapse-transition="false"
  >
    <template v-for="item in sideData">
      <el-submenu :index="item.id" :key="item.title" v-if="item.children">
        <template slot="title">
          <i :class="['iconfont', 'icon-position', item.icon]"></i>
          <span>{{ item.title }}</span>
        </template>
        <template v-for="i in item.children">
          <el-submenu :index="i.id" :key="i.title" v-if="i.children">
            <template slot="title">
              <span>{{ i.title }}</span>
            </template>
            <template v-for="ii in i.children">
              <el-submenu :index="ii.id" :key="ii.title" v-if="ii.children">
                <template slot="title">
                  <span>{{ ii.title }}</span>
                </template>
                <template v-for="device in ii.children">
                  <el-menu-item
                    :index="device.id"
                    :key="device.title"
                    @click="
                      $router.push({
                        path: `/charts/${device.id}?name=${device.title}`,
                      })
                    "
                  >
                    {{ device.title }}
                  </el-menu-item>
                </template>
              </el-submenu>
              <el-menu-item
                v-else
                :index="ii.id"
                :key="ii.title"
                @click="$router.push({ path: ii.path })"
                >{{ ii.title }}</el-menu-item
              >
            </template>
          </el-submenu>
          <el-menu-item
            v-else
            :index="i.id"
            :key="i.title"
            @click="
              $router.push({ path: i.path });
              extraSet(i.id);
            "
            >{{ i.title }}</el-menu-item
          >
        </template>
      </el-submenu>
      <el-menu-item
        :index="item.id"
        :key="item.title"
        v-else
        @click="$router.push({ path: item.path })"
      >
        <i :class="['iconfont', 'icon-position', item.icon]"></i>
        <span slot="title">{{ item.title }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
// import bus from "../../utils/bus";
export default {
  name: "SideBar",
  props: {
    data: [],
  },
  data() {
    return {
      active: "dashboards",
      sideData: [
        {
          id: "dashboards",
          title: "首页",
          icon: "icon-home",
          children: null,
          path: "/dashboards",
        },
        {
          id: "2",
          title: "所有设备",
          icon: "icon-menu",
          children: null,
          // children: [
          //   {
          //     id: 123,
          //     index: "1-1",
          //     title: "一年级",
          //     children: [
          //       {
          //         id: 12345,
          //         index: "1-1-1",
          //         title: "一年级一班",
          //       },
          //       {
          //         id: 12346,
          //         index: "1-1-2",
          //         title: "一年级二班",
          //       },
          //     ],
          //   },
          //   {
          //     id: 124,
          //     index: "1-2",
          //     title: "二年级",
          //     children: [
          //       {
          //         id: 12347,
          //         index: "1-2-1",
          //         title: "二年级三班",
          //       },
          //     ],
          //   },
          // ],
        },
        {
          id: "alarm",
          title: "告警信息",
          icon: "icon-baojing",
          path: "/alarm",
          children: null,
        },
        {
          id: "4",
          title: "系统设置",
          icon: "icon-set",
          children: [
            {
              id: "myMessage",
              title: "我的消息",
              children: null,
              path: "/myMessage",
            },
            {
              id: "helpInfo",
              title: "客户帮助",
              children: null,
              path: "/HelpInfo",
            },
            {
              id: "contactUs",
              title: "客户服务",
              children: null,
              path: "/contactUs",
            },
            {
              id: "profile",
              title: "系统信息",
              children: null,
              path: "/profile",
            },

            // {
            //   index: "2",
            //   title: "关于",
            //   children: [],
            // },
            {
              id: "logout",
              title: "退出登录",
              children: null,
            },
            // {
            //   id: "iot",
            //   title: "返回Iot平台",
            //   children: null,
            // },
          ],
        },
      ],
      // defaultActive: "1",
    };
  },
  methods: {
    getSideBarList() {
      this.$http
        .getSideBar(window.localStorage.getItem("assetID"))
        .then((res) => {
          console.log(res);
          this.sideData[1].children = res.data;
        });
    },
    extraSet(id) {
      if (id === "logout") {
        window.localStorage.clear();
        this.$router.push({
          path: "/login",
        });
        window.location.reload();
      } else if (id === "iot") {
        window.location.href = "https://user.jiandeng.tech/#/home";
      }
    },
    // emitTitle(title) {
    //   bus.$emit("changeTitle", title);
    // },
  },
  created() {
    this.getSideBarList();
  },
  mounted() {
    let activeArray = this.$route.fullPath.split("/");
    this.active = activeArray[activeArray.length - 1].split("?")[0];
    console.log(this.active, "ac");
  },
  computed: {
    isCollapse() {
      return this.$store.state.isCollapse;
    },
  },
  watch: {
    "$route.path"(newValue) {
      if (newValue.includes("detail")) {
        this.active = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-aside {
  > .el-menu {
    height: 100%;
    //border-right: none;
    //background: #324357;
    .icon-position {
      margin-right: 10px;
      color: #333;
      font-size: 20px;
    }

    ::v-deep.el-submenu {
      > .el-submenu__title {
        padding-left: 20px !important;
        > span {
          //padding-left: 20px;
        }
        &:hover {
          background-color: #f4f4f6 !important;
        }
      }

      .el-menu-item {
        min-width: 0;
        padding-left: 40px !important;
      }

      &.is-active {
        .icon-position {
          color: var(--themeColor);
        }
      }
    }
    > .el-menu-item {
      //padding-left: 40px !important;
    }
  }
  .el-menu-item {
    &:hover {
      background-color: #f4f4f6 !important;
    }
    &.is-active {
      color: var(--themeColor);
      background-color: #f4f4f6 !important;
      &::before {
        content: "";
        width: 4px;
        height: 100%;
        background: var(--themeColor);
        position: absolute;
        left: 0;
        top: 0;
      }
      .icon-position {
        color: var(--themeColor);
      }
    }
  }
}
//.el-menu-vertical:not(.el-menu--collapse) {
//  width: 100%;
//  height: calc(100%- 48px);
//}

.el-menu--collapse {
  width: auto;
}
</style>
