/**
 * 首页
 */
import { request } from "@/service/request";

export default {
  //获取一周在线数量
  getOnlineStatistics: (assetId) =>
    request({ url: `newAir/onlineStatistics/${assetId}` }),
  //获取滤网需维护数量及首页报警信息
  getFilterAndAlarmInfo: (assetId) =>
    request({ url: `newAir/findFilterInformation/${assetId}` }),
  // 新风机待维护数量
  getAirWaitMaintain: (assetId) =>
    request({ url: `newAir/waitMaintain/${assetId}` }),
  //获取新风机状态
  getAirState: (assetId) => request({ url: `newAir/statusInfo/${assetId}` }),
  //获取设备列表
  getDeviceList: (assetId, pageNum, pageSize) =>
    request({
      url: `newAir/firstPage/${assetId}?pageNum=${pageNum}&pageSize=${pageSize}`,
    }),
  //获取单个设备状态
  getSideBar: (assetId) => request({ url: `newAir/allDevice/${assetId}` }),
  //单独改变设备状态
  getDeviceState: (deviceId, type, params) =>
    request({
      url: `plugins/rpc/twoway/${deviceId}`,
      method: "post",
      params: {
        method: type,
        params: params,
        timeout: 3000,
      },
    }),
  // 获取设备定时状态
  getDeviceTiming: (deviceId) =>
    request({ url: `newAir/getTiming/${deviceId}` }),

  //改变设备定时状态
  setDeviceTiming: (deviceId, flag) =>
    request({ url: `newAir/isEnable/${deviceId}?flag=${flag}` }),
};
