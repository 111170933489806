<template>
  <div>{{ wea }} {{ minTem }}-{{ maxTem }}°C {{ showDate }}</div>
</template>

<script>
import { weekTransform } from "../../utils/week";

export default {
  name: "LocalWeather",
  data() {
    return {
      wea: "晴",
      minTem: 0,
      maxTem: 0,
      showDate: "",
    };
  },
  methods: {
    date() {
      const date = new Date();
      let minute = date.getMinutes() + "";
      if (minute.length === 1) {
        minute = "0" + minute;
      }
      this.showDate = `${date.getHours()}:${minute}  ${date.getFullYear()}年${
        date.getMonth() + 1
      }月${date.getDate()}日 星期${weekTransform(date.getDay())}`;
    },
  },
  mounted() {
    setInterval(() => {
      this.date();
    }, 1000);
    this.$http.getLocalWeather().then((res) => {
      console.log(res.data);
      const { wea = "晴", minTem = 0, maxTem = 0 } = res.data;
      this.wea = wea;
      this.minTem = minTem;
      this.maxTem = maxTem;
    });
  },
};
</script>

<style scoped></style>
