/**
 * 登录
 */
import { request } from "@/service/request";

export default {
  getToken: (userId) => request({ url: `user/${userId}/token` }),
  postRefreshToken: (params) =>
    request({ url: "auth/token", method: "post", params, isLoop: true }),
  login: (params) =>
    request({ url: "auth/login", method: "post", params, isToken: false }),
  getAuthCode: (params) =>
    request({ url: `newAir/getAuthCode/${params}`, method: "get" }),
  phoneLogin: (params) =>
    request({ url: `auth/code/login`, method: "post", params }),
};
