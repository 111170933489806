<template>
  <div class="user-menu">
    <div class="user-menu-left">
      <i class="iconfont icon-yonghu avatar"></i>
      <div class="user-menu-info">
        <span>{{ username }}</span>
        <span>客户</span>
      </div>
    </div>
    <el-dropdown trigger="click" @command="handleCommand">
      <i class="iconfont icon-gengduo more-option"></i>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="sysInfo">系统信息</el-dropdown-item>
        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        <!--        <el-dropdown-item command="iot">返回IOT平台</el-dropdown-item>-->
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
// import { getToken } from "../../utils/token";

export default {
  name: "UserMenu",
  methods: {
    handleCommand(command) {
      if (command === "sysInfo") {
        this.$router.push({
          path: "/profile",
        });
      } else if (command === "logout") {
        window.localStorage.clear();
        this.$router.push({
          path: "/login",
        });
        window.location.reload();
      } else if (command === "iot") {
        window.location.href = "https://user.jiandeng.tech/#/home";
      }
    },
  },
  computed: {
    username() {
      return this.$store.state.username;
    },
  },
};
</script>

<style scoped lang="scss">
.user-menu {
  font-size: 14px;
  display: flex;
  align-items: center;
  .user-menu-left {
    display: flex;
    align-items: center;
    .avatar {
      font-size: 28px;
    }
    .user-menu-info {
      height: 36px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
      span {
        width: 150px;
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /* 超出部分显示省略号 */
        white-space: nowrap; /*规定段落中的文本不进行换行 */
      }
    }
  }
  .more-option {
    font-size: 26px;
    margin-left: 6px;
    cursor: pointer;
    color: #ffffff;
  }
}
</style>
