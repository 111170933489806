/**
 * 客户帮助
 */
import { request } from "@/service/request";

export default {
  findAllHelpInfoIdAndTitle: () =>
    request({ url: `backgroundManagement/helpInfo/findAllHelpInfoIdAndTitle` }),
  findBodyByHelpInfoId: (Id) =>
    request({
      url: `backgroundManagement/helpInfo/findBodyByHelpInfoId/${Id}`,
    }),
  // findAllContactUsByCustomer: () =>
  //   request({
  //     url: `backgroundManagement/contactUs/findAllContactUsByCustomer`,
  //   }),
};
