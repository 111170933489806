<template>
  <div class="date-block-container">
    <span
      :class="['date-block-day', active === 1 ? 'active' : '']"
      @click="changeDate(0)"
      >日</span
    >
    <span
      :class="['date-block-week', active === 2 ? 'active' : '']"
      @click="changeDate(7)"
      >周</span
    >
    <span
      :class="['date-block-month', active === 3 ? 'active' : '']"
      @click="changeDate(30)"
      >月</span
    >
  </div>
</template>

<script>
import bus from "../../utils/bus";
export default {
  name: "DateBlock",
  props: {
    active: {
      type: Number,
      default: 1,
    },
    cpn: String,
  },
  data() {
    return {
      currentNum: 1,
    };
  },
  // data() {
  //   return {
  //     active: 1,
  //   };
  // },
  methods: {
    rel(num) {
      switch (num) {
        case 0:
          return 1;
        case 7:
          return 2;
        case 30:
          return 3;
      }
    },
    changeDate(dateNum) {
      if (this.rel(dateNum) === this.currentNum) return;
      // this.currentNum = this.active;
      bus.$emit("changeDate", dateNum, this.cpn);
    },
  },
  watch: {
    active() {
      this.currentNum = this.active;
    },
  },
};
</script>

<style scoped lang="scss">
.date-block-container {
  display: flex;
  flex-direction: row;
  span {
    border: 1px solid #bcb1a0;
    width: 40px;
    height: 20px;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    margin: 0 -1px -1px 0;
  }
  .active {
    color: #01cfa5;
    border: 1px solid #01cfa5;
    z-index: 10;
  }
}
</style>
