import Vue from "vue";
import Vuex from "vuex";
import api from "../api";
import router from "@/router";
import { setToken } from "../utils/token";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    username: "",
    phone: "",
    userID: "",
    assetName: "",
    logoUrl: "",
    detailRequireConfirm: false,
    airState: 0,
    filterState: {
      primaryFilter: 0,
      mediumFilter: 0,
      highFilter: 0,
    },
    alarmList: [],
    maintainedAirList: [],
    maintainedPrimaryFilterList: [],
    maintainedMediumFilterList: [],
    maintainedHighFilterList: [],
    airListRequire: false,
    filterListPrimaryRequire: false,
    filterListMediumRequire: false,
    filterListHighRequire: false,
    themeColor: "",
    isCollapse: false,
    helpInfoId: "",
  },
  mutations: {
    changeAirState(state, airState) {
      state.airState = airState;
    },
    changeFilterState(state, filterState) {
      state.filterState = filterState;
    },
    changeAlarmList(state, alarmList) {
      state.alarmList = alarmList;
    },
    changeMaintainedAirList(state, airList) {
      state.maintainedAirList = airList;
      state.airListRequire = true;
    },
    changeMaintainedPrimaryFilterList(state, filterList) {
      state.maintainedPrimaryFilterList = filterList;
      state.filterListPrimaryRequire = true;
    },
    changeMaintainedMediumFilterList(state, filterList) {
      state.maintainedMediumFilterList = filterList;
      state.filterListMediumRequire = true;
    },
    changeMaintainedHighFilterList(state, filterList) {
      state.maintainedHighFilterList = filterList;
      state.filterListHighRequire = true;
    },
    changeAssetNameState(state, assetName) {
      state.assetName = assetName;
    },
    changeLogoUrl(state, logoUrl) {
      state.logoUrl = logoUrl;
    },
    changeUsername(state, username) {
      state.username = username;
      localStorage.setItem("username", username);
    },
    changeDetailRequireConfirm(state) {
      state.detailRequireConfirm = true;
    },
    SET_THEME_COLOR: (state, color) => {
      if (color) {
        state.themeColor = color;
        window.localStorage.setItem("themeColor", color);
      }
    },
    // changeState(state) {},
    changeCollapse(state, isCollapse) {
      state.isCollapse = isCollapse;
    },
    changeUserInfo(state, { username, phone, id }) {
      state.username = username;
      state.phone = phone;
      state.userID = id;
    },
    changeAssetID(state, assetID) {
      window.localStorage.setItem("assetID", assetID);
    },
  },
  actions: {
    getAirStateAction({ commit }) {
      return api
        .getAirWaitMaintain(window.localStorage.getItem("assetID"))
        .then((res) => {
          const num = res?.data.waitMaintain || 0;
          commit("changeAirState", num);
        });
    },

    getFilterState({ commit }) {
      return api
        .getFilterAndAlarmInfo(window.localStorage.getItem("assetID"))
        .then((res) => {
          const filterState = {
            primaryFilter: res?.data.primaryFilterNeedChangeNumber || 0,
            mediumFilter: res?.data.mediumFilterNeedChangeNumber || 0,
            highFilter: res?.data.highFilterNeedChangeNumber || 0,
          };
          const alarmList = res?.data.alarmInformation || [];
          commit("changeFilterState", filterState);
          commit("changeAlarmList", alarmList);
        });
    },

    getMaintainedAirList({ commit }) {
      return api
        .getAllMaintain(window.localStorage.getItem("assetID"))
        .then((res) => {
          commit("changeMaintainedAirList", res?.data || []);
        });
    },

    getMaintainedFilterList({ commit }, payload) {
      return api
        .getAllFilter(window.localStorage.getItem("assetID"), payload)
        .then((res) => {
          console.log(res, "filter");
          if (payload === "primary") {
            commit("changeMaintainedPrimaryFilterList", res?.data || []);
          } else if (payload === "medium") {
            commit("changeMaintainedMediumFilterList", res?.data || []);
          } else {
            commit("changeMaintainedHighFilterList", res?.data || []);
          }
        });
    },

    getAssetNameAction({ commit }) {
      api.getAssetInfo(window.localStorage.getItem("assetID")).then((res) => {
        console.log(res, "asset");
        commit("changeAssetNameState", res.data.name);
      });
    },

    getLogoUrl({ commit }) {
      api.getLogoUrl(window.localStorage.getItem("assetID")).then((res) => {
        console.log(res, "logo");
        commit("changeLogoUrl", res.data);
      });
    },

    login({ commit }, { username, password }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const result = await api.login({ username, password });
          const { token, refreshToken } = result.data;
          setToken(token, refreshToken);
          const result2 = await api.getAssetID();
          const assetID = result2.data ? result2.data[0] : null;
          commit("changeAssetID", assetID);
          commit("changeUsername", username);
          // const userInfo = await api.getUser();
          // console.log(userInfo);
          // commit("SET_USER_INFO", userInfo.data);
          // commit('SET_PERMISSION_ROUTER', userInfo.data.authority)
          router.push({ path: "/dashboards" });
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
    },

    // eslint-disable-next-line no-unused-vars
    phoneLogin({ commit }, { mobilePhone, code }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const result = await api.phoneLogin({ mobilePhone, code });
          const { token, refreshToken } = result.data;
          setToken(token, refreshToken);
          const result2 = await api.getAssetID();
          const assetID = result2.data ? result2.data[0] : null;
          commit("changeAssetID", assetID);
          // commit("changeUsername", mobilePhone);
          // const userInfo = await api.getUser();
          // console.log(userInfo);
          // commit("SET_USER_INFO", userInfo.data);
          // commit('SET_PERMISSION_ROUTER', userInfo.data.authority)
          router.push({ path: "/dashboards" });
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
    },
    userLoginProfile({ commit }) {
      api.getUserProfile().then((res) => {
        if (res.data) {
          console.log(res.data, "profile");
          const id = res.data.id?.id;
          const phone = res.data?.mobilePhone;
          const username = res.data?.email;
          commit("changeUserInfo", { username, phone, id });
        }
      });
    },
    logout({ commit }) {
      commit("changeState");
    },
  },
  getters: {
    themeColor: (state) => {
      if (state.themeColor) {
        return state.themeColor;
      } else {
        return window.localStorage.getItem("themeColor") || "#01d0a4";
      }
    },
  },
  helpInfo: { id: "" },
  modules: {},
});
